# # размеры landing cardsmenu
	#
	# использование :
  #   landing_cardsmenu_dem()
	#
landing_cardsmenu_dem = ->
	# получаем все карточки
	cards = $('.карточка', main_ids().cardsmenu)
	# получаем первую карточку
	onecard = $('.карточка:first', main_ids().cardsmenu)
	# получаем padding первой карточки
	onecard_padding = parseInt(onecard.css('padding'))
	# получаем высоту .титл первой карточки
	title_height = $('.титл', onecard).innerHeight()
	# получаем margin-bottom первой карточки
	title_margin = parseInt($('.титл', onecard).css('margin-bottom'))
	# вычисляем размер introtext на снове полученных данных
	new_content_height = onecard.innerHeight() - onecard_padding*2 - title_height - title_margin - 2
	# устанавливаем introtext height ВСЕМ карточкам
	$('.introtext', cards).css('height', new_content_height + 'px')

	#
	return
# ==========================================
